import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import { useEffect } from "react";
import NotFound from "./Pages/404";
import AOS from "aos";
import Login from "./Pages/Login";
import Home from "./Pages/PageRoutes";
import ProtectedRoute from "./components/Login/ProtectedRoute";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import PageRoutes from "./Pages/PageRoutes";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/*" element={<PageRoutes />} />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
