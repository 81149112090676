import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import WPStats from "../components/Stats/WPStats";
import MUsers from "../components/Users/MUsers";
import PortalUsers from "../components/Users/PortalUsers";
import Invoicing from "../components/Invoicing/Invoicing";
import NewInvoice from "../components/Invoicing/NewInvoice";
import PreviewInvoice from "../components/Invoicing/PreviewInvoice";
import EditInvoice from "../components/Invoicing/EditInvoice";
//Quotes
import Quote from "../components/Quotes/Quote";
import NewQuote from "../components/Quotes/NewQuote";
import PreviewQuote from "../components/Quotes/PreviewQuote";
import EditQuote from "../components/Quotes/EditQuote";
//
import Payroll from "../components/Payroll/Payroll";
import Payslip from "../components/Payroll/Payslip";
import Employees from "../components/Payroll/Employees";
import Settings from "../components/Settings/Settings";
import NotFound from "./NotFound";
import Navbar from "./Navbar";

export default function PageRoutes() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar user={user} setUser={setUser} />
      <Box sx={{ flexGrow: 1, p: 2, mt: 9 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Routes>
            <Route path="/home" element={<WPStats user={user} />} />
            <Route path="/customers" element={<MUsers user={user} />} />
            <Route path="/users" element={<PortalUsers user={user} />} />
            <Route path="/invoices" element={<Invoicing user={user} />} />
            <Route path="/invoices/new" element={<NewInvoice user={user} />} />
            <Route
              path="/invoices/edit/*"
              element={<EditInvoice user={user} />}
            />
            <Route
              path="/invoices/preview/*"
              element={<PreviewInvoice user={user} />}
            />
            {/* Quotes */}
            <Route path="/quotes" element={<Quote user={user} />} />
            <Route path="/quotes/new" element={<NewQuote user={user} />} />
            <Route path="/quotes/edit/*" element={<EditQuote user={user} />} />
            <Route
              path="/quotes/preview/*"
              element={<PreviewQuote user={user} />}
            />

            <Route path="/payroll" element={<Payroll user={user} />} />
            <Route path="/payroll/payslip" element={<Payslip user={user} />} />
            <Route
              path="/payroll/employees"
              element={<Employees user={user} />}
            />
            <Route path="/settings" element={<Settings user={user} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </Box>
    </Box>
  );
}
