import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  Alert,
  Grid2,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";

export default function Settings({ user }) {
  const [userData, setUserData] = useState({
    Name: user?.Name || "",
    Email: user?.Email || "",
    Phone: user?.Phone || "",
    Position: user?.Position || "",
  });
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [dloading, setDLoading] = useState(false);
  const [ploading, setPLoading] = useState(false);
  const [usr, setUsr] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    special: false,
  });

  const checkPasswordCriteria = (password) => {
    setPasswordCriteria({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  useEffect(() => {
    checkPasswordCriteria(newPassword);
  }, [newPassword]);

  // Update password handler
  const handlePasswordUpdate = async (event) => {
    event.preventDefault();
    setUsr(false);
    setMessage(null); // Clear previous messages

    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match");
      setSeverity("error");
      return;
    }

    if (
      !passwordCriteria.digit ||
      !passwordCriteria.length ||
      !passwordCriteria.lowercase ||
      !passwordCriteria.special ||
      !passwordCriteria.uppercase
    ) {
      setMessage("Enter a strong password!");
      setSeverity("error");
      return;
    }

    setPLoading(true);
    try {
      const response = await fetch(`/api/auth/change-password`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ oldPassword, newPassword }),
      });
      const data = await response.json();

      setMessage(data.message || "Password updated successfully.");
      setSeverity(data.message.includes("success") ? "success" : "error");
    } catch (error) {
      setMessage("Failed to update password.");
      setSeverity("error");
    }
    setPLoading(false);
  };

  // Update user details handler
  const handleUserUpdate = async () => {
    setDLoading(true);
    setUsr(true);
    try {
      const response = await fetch(`/api/auth/${user?.UserID}`, {
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
      });
      const data = await response.json();

      setMessage(data.message || "User details updated successfully.");
      setSeverity(data.message.includes("success") ? "success" : "error");
    } catch (error) {
      setMessage("Failed to update user details.");
      setSeverity("error");
    }
    setDLoading(false);
  };

  return (
    <Box padding={1}>
      <Stack spacing={3}>
        <Typography variant="h6">Settings</Typography>
        <Card
          style={{
            borderRadius: "16px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          }}
        >
          <CardHeader title="User Details" />
          <Divider />
          <CardContent>
            <Stack spacing={2}>
              {["Name", "Email", "Phone", "Position"].map((field) => (
                <FormControl size="small" key={field} fullWidth>
                  <InputLabel>{field}</InputLabel>
                  <OutlinedInput
                    label={field}
                    value={userData[field]}
                    disabled={
                      field == "Name" || field == "Email" || field == "Position"
                    }
                    onChange={(e) =>
                      setUserData({ ...userData, [field]: e.target.value })
                    }
                  />
                </FormControl>
              ))}
              {usr && message && <Alert severity={severity}>{message}</Alert>}
            </Stack>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              size="small"
              variant="contained"
              onClick={handleUserUpdate}
              disabled={dloading}
            >
              {dloading ? "Submitting..." : "Update Details"}
            </Button>
          </CardActions>
        </Card>

        {/* Password Update Form */}
        <form onSubmit={handlePasswordUpdate}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <CardHeader subheader="Update password" title="Password" />
            <Divider />
            <CardContent>
              <Stack spacing={3}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      {passwordCriteria.length ? (
                        <Check sx={{ color: "green" }} fontSize="small" />
                      ) : (
                        <Close sx={{ color: "red" }} fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText secondary="At least 8 characters long" />
                  </ListItem>
                  <ListItem size="small">
                    <ListItemIcon>
                      {passwordCriteria.uppercase ? (
                        <Check sx={{ color: "green" }} fontSize="small" />
                      ) : (
                        <Close sx={{ color: "red" }} fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText secondary="At least one uppercase letter" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {passwordCriteria.lowercase ? (
                        <Check sx={{ color: "green" }} fontSize="small" />
                      ) : (
                        <Close sx={{ color: "red" }} fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText secondary="At least one lowercase letter" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {passwordCriteria.digit ? (
                        <Check sx={{ color: "green" }} fontSize="small" />
                      ) : (
                        <Close sx={{ color: "red" }} fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText secondary="At least one digit" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {passwordCriteria.special ? (
                        <Check sx={{ color: "green" }} fontSize="small" />
                      ) : (
                        <Close sx={{ color: "red" }} fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText secondary="At least one special character" />
                  </ListItem>
                </List>
                <FormControl size="small" fullWidth>
                  <InputLabel>Old Password</InputLabel>
                  <OutlinedInput
                    label="Old Password"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </FormControl>

                <Grid2 container spacing={2}>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <FormControl size="small" fullWidth>
                      <InputLabel>New Password</InputLabel>
                      <OutlinedInput
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </FormControl>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <FormControl size="small" fullWidth>
                      <InputLabel>Confirm Password</InputLabel>
                      <OutlinedInput
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          if (e.target.value != newPassword) {
                            setMessage("Passwords do not match");
                            setSeverity("error");
                          } else setMessage("");
                        }}
                      />
                    </FormControl>
                  </Grid2>
                </Grid2>
                {!usr && message && (
                  <Alert severity={severity}>{message}</Alert>
                )}
              </Stack>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button
                size="small"
                variant="contained"
                type="submit"
                disabled={ploading}
              >
                {ploading ? "Submitting..." : "Update Password"}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Stack>
    </Box>
  );
}
