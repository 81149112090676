import React, { cloneElement, Fragment, useEffect, useState } from "react";
import {
  Home,
  People,
  Settings,
  Logout,
  ExpandLess,
  ExpandMore,
  Dashboard,
  Message,
  Commute,
  AccountCircle,
  EventNote,
  PeopleAlt,
  Euro,
  MoneyOff,
  CurrencyBitcoin,
  CreditCard,
  Person,
  NotificationAdd,
  Payment,
  DocumentScanner,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box } from "@mui/material";
import { Gear, Invoice, Quotes } from "@phosphor-icons/react";
import Header from "../components/Header/Header";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: "100%",
  marginLeft: 0,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Navbar = (props) => {
  const { user } = props; // Expecting user role from props
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [openSections, setOpenSections] = useState({
    customerService: false,
    riskManagement: false,
    debtCollection: false,
  });

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleToggle = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const logout = () => {
    fetch(`/api/admin/logout`).then((res) => {
      navigate("/");
    });
  };

  // Define menu items based on user role
  const menuItems = [
    { text: "Home", icon: <Home />, path: "/home" },
    { text: "Invoices", icon: <Invoice />, path: "/invoices" },
    { text: "Quotes", icon: <DocumentScanner />, path: "/quotes" },
    { text: "Customers", icon: <People />, path: "/customers" },
  ];

  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Header
            setUser={props.setUser}
            handleDrawerOpen={handleDrawerOpen}
            open={open}
          />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box></Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <Fragment key={item.text}>
              {item.subItems ? (
                <>
                  <ListItem button onClick={() => handleToggle(item.text)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      sx={{
                        fontSize: "small",
                        color:
                          location.pathname === item.path
                            ? "primary"
                            : "textSecondary",
                        fontWeight:
                          location.pathname === item.path ? "bold" : "normal", // Highlight text for selected item
                      }}
                      primary={item.text}
                    />
                    {openSections[item.text] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {openSections[item.text] && (
                    <List component="div" disablePadding>
                      {item.subItems.map((subItem) => (
                        <ListItem
                          key={subItem.text}
                          button
                          onClick={() => navigate(subItem.path)}
                          selected={location.pathname === subItem.path}
                          sx={{
                            fontSize: "x-small",
                            pl: 4, // Indent subitems
                            typography: "body2", // Reduce font size
                            fontStyle: "italic", // Italicize text
                            bgcolor:
                              location.pathname === subItem.path
                                ? "action.selected"
                                : "transparent", // Highlight selected subitem
                          }}
                        >
                          <ListItemIcon>{subItem.icon}</ListItemIcon>
                          <ListItemText
                            primary={subItem.text}
                            sx={{
                              fontSize: "x-small",
                              color:
                                location.pathname === item.path
                                  ? "primary"
                                  : "textSecondary",
                              fontWeight:
                                location.pathname === item.path
                                  ? "bold"
                                  : "normal", // Highlight text for selected item
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </>
              ) : (
                <ListItem
                  key={item.text}
                  button
                  onClick={() => navigate(item.path)}
                  selected={location.pathname === item.path}
                  sx={{
                    bgcolor:
                      location.pathname === item.path
                        ? "action.selected"
                        : "transparent", // Highlight selected item
                  }}
                >
                  <ListItemIcon>
                    {cloneElement(item.icon, {
                      color:
                        location.pathname === item.path
                          ? "primary"
                          : "textSecondary",
                    })}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      color:
                        location.pathname === item.path
                          ? "primary"
                          : "textSecondary",
                      fontWeight:
                        location.pathname === item.path ? "bold" : "normal", // Highlight text for selected item
                    }}
                  />
                </ListItem>
              )}
            </Fragment>
          ))}
        </List>
        <Divider />
        <List>
          {user && user.Department === "Admin" && (
            <ListItem button onClick={() => navigate("/users")}>
              <ListItemIcon>
                <PeopleAlt />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          )}
          <ListItem button onClick={() => navigate("/settings")}>
            <ListItemIcon>
              <Gear size={24} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem button onClick={logout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Navbar;
