import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4FC3F1", // Replace this with your desired primary color
      light: "#ffffff",
    },
    secondary: {
      main: "#0033A0", // Replace this with your desired primary color
    },
    black: {
      main: "#000000", // Replace this with your desired primary color
    },
  },
});

export default theme;
