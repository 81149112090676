import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Alert,
  Link as RouterLink,
  Divider,
  IconButton,
} from "@mui/material";
import Link from "@mui/material/Link";
import ForgotPassword from "./ForgotPassword";
import logo from "../assets/images/logo.png";
import { ArrowBackIos, Email } from "@mui/icons-material";

const defaultValues = { Email: "", Password: "", Code: "" };

export default function LoginPage() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0); // 0: Login, 1: Code Verification
  const [isError, setIsError] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [severity, setSeverity] = useState("error");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [showing, setShowing] = useState(false);
  const [formData, setFormData] = useState(defaultValues);

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const onSubmit = async (event) => {
    if (event) {
      event.preventDefault();
      setLoading(true);
    }
    setIsError("");

    const { Email, Password } = formData;
    setEmail(Email);

    if (!Email || !Password) {
      setLoading(false);
      setIsError("Email and Password are required.");
      return;
    }

    if (!validateEmail(Email)) {
      setLoading(false);
      setIsError("Please enter a valid email address.");
      return;
    }

    try {
      const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ Email, Password }),
      });

      const body = await response.json();
      setLoading(false);
      if (response.ok) {
        setSeverity("success");
        setIsError(body.message);
        setTimeout(() => {
          setToken(body.token); // Store token from response
          setIsError("");
          if (event) {
            setActiveStep(1); // Go to code verification step
          }
        }, 3000);
      } else {
        setSeverity("error");
        setIsError(body.message);
      }
    } catch (err) {
      setLoading(false);
      setSeverity("error");
      setIsError(err.message);
    }
  };

  const confirmCode = async (event) => {
    event.preventDefault();
    setIsError("");
    setLoading(true);

    if (!code || code.length !== 6) {
      setLoading(false);
      setSeverity("error");
      setIsError("Enter a valid OTP");
      return;
    }

    try {
      const response = await fetch("/api/auth/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`, // Include token if necessary
        },
        body: JSON.stringify({ Code: code, Email: email }),
      });

      const body = await response.json();
      setLoading(false);

      if (response.ok) {
        setSeverity("success");
        setSeverity("success");
        setIsError(body.message);
        setTimeout(() => {
          navigate("/home");
        }, 2000);
      } else {
        setSeverity("error");
        setIsError(body.message || "Verification failed!");
      }
    } catch (err) {
      setLoading(false);
      setSeverity("error");
      setIsError("An error occurred during verification. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    setIsError("");
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: "radial-gradient(circle, #4FC3F1 0%, #0033A0 100%)",
        position: "relative",
      }}
    >
      <Box sx={{ position: "absolute", top: "2em", left: "7.5%" }}>
        <Typography color="white">Powered by:</Typography>
        <Typography
          sx={{ cursor: "pointer", ":hover": { color: "orange" } }}
          fontWeight="bold"
          color="white"
          fontSize="1.5rem"
          onClick={() => {
            window.open("https://finnai.co.ke", "_blank");
          }}
        >
          Finnai Tech Solutions
        </Typography>
      </Box>
      <Card
        sx={{
          p: 4,
          mt: 4,
          maxWidth: { xs: "85vw", md: "35vw" },
          width: "100%",
          borderRadius: "8px",
          boxShadow: "0px 4px 32px #60606040",
        }}
      >
        {activeStep === 0 && (
          <form onSubmit={onSubmit}>
            <Box display="flex" gap={4} mb={5}>
              <img
                style={{
                  maxHeight: "44px",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={logo}
              />
              <Box m="auto">
                <Typography variant="h4" fontWeight="bold" color="primary">
                  Invoicing App
                </Typography>
              </Box>
              <Box width="44px"></Box>
            </Box>

            <Stack spacing={2}>
              <FormControl fullWidth>
                <InputLabel>Email address</InputLabel>
                <OutlinedInput
                  name="Email"
                  label="Email address"
                  type="email"
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>Password</InputLabel>
                <OutlinedInput
                  name="Password"
                  label="Password"
                  type="password"
                  onChange={handleInputChange}
                />
              </FormControl>
              <Link
                component={RouterLink}
                onClick={() => {
                  setShowing(true);
                }}
                variant="subtitle2"
                sx={{ textAlign: "center", color: "#0033A0" }}
              >
                Forgot password?
              </Link>
              {isError && <Alert severity={severity}> {isError}</Alert>}
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                fullWidth
                sx={{ color: "white", textTransform: "capitalize" }}
              >
                {loading ? "Signing In..." : "Sign In"}
              </Button>
            </Stack>
          </form>
        )}
        {activeStep === 1 && (
          <form onSubmit={confirmCode}>
            <Box display="flex" gap={4} mb={5}>
              <img
                style={{
                  maxHeight: "44px",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={logo}
              />
              <Box m="auto">
                <Typography variant="h4" fontWeight="bold" color="primary">
                  OTP Verification
                </Typography>
              </Box>
              <Box width="44px">
                <IconButton
                  onClick={() => {
                    setActiveStep(0);
                  }}
                >
                  <ArrowBackIos />
                </IconButton>
              </Box>
            </Box>
            <Stack spacing={2}>
              <FormControl fullWidth>
                <InputLabel>OTP</InputLabel>
                <OutlinedInput
                  name="Code"
                  label="OTP"
                  type="text"
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                />
              </FormControl>
              <Link
                component={RouterLink}
                onClick={() => {
                  onSubmit();
                }}
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "#0033A0",
                  cursor: "pointer",
                }}
              >
                Resend Code
              </Link>
              {isError && <Alert severity={severity}>{isError}</Alert>}
              <Button
                type="submit"
                disabled={loading}
                variant="contained"
                fullWidth
                sx={{ color: "white", textTransform: "capitalize" }}
              >
                {loading ? "Verifying..." : "Verify OTP"}
              </Button>
            </Stack>
          </form>
        )}
        <ForgotPassword open={showing} showForgotPassword={setShowing} />
      </Card>
    </Box>
  );
}
